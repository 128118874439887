import { Component, Logger, $ } from 'lib'
import locale from 'Common/Locale/Locale'
import util from 'Common/Util/Util'
import RewardsStatusInformer from 'Component/Rewards/StatusInformer/RewardsStatusInformer'
import PartnerBanner from './PartnerBanner/PartnerBanner'

class WatchMenu extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
    this.addListeners()
  }

  addListeners () {
    this.showTab = (event, tabName) => {
      const clickedTab = $(event.currentTarget)

      if (clickedTab.hasClass('selected')) return

      $('.tab.selected').removeClass('selected')
      clickedTab.addClass('selected')

      this.props.tabChangeHandler && this.props.tabChangeHandler(tabName)
    }
  }

  renderTab (tabName) {
    const selectedClass = this.props.selectedMenuTab === tabName && 'selected'
    const tabClasses = util.classNames('tab', 'title', tabName, selectedClass)

    return (
      <div
        class={ tabClasses }
        onclick={ (event) => this.showTab(event, tabName) }
        role="button">
        { locale.translate('watch.menu.' + tabName) }
      </div>
    )
  }

  render () {
    if (!this.props.tabs) {
      return this.log.error('Tabs prop required')
    }

    return (
      <div class="WatchMenu">
        <ul class="menu">
          { this.props.tabs.map((tab) => this.renderTab(tab)) }
        </ul>
        <PartnerBanner tournamentId={ this.props.tournamentId }/>
        <RewardsStatusInformer
          gameId={ this.props.gameId }
          tournamentId={ this.props.tournamentId }
          watchType={ this.props.watchType }
        />
      </div>
    )
  }
}

export default WatchMenu
