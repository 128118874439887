import { $ } from 'lib'
import riotBar from 'Common/Service/RiotBar/RiotBar'
import { leaguePriority } from '../RelApi/RelApi'

// If you change this ID, also change it in src/index.html, the noscript
// fallback has the ID hardcoded there.
const id = 'GTM-MBFKBN'
const url = 'https://www.googletagmanager.com/gtm.js?id=' + id

class Google {
  setupMetrics () {
    this.addListeners()

    window.ga
      = window.ga
      || function () {
        ;(window.ga.q = window.ga.q || []).push(arguments)
      }
    window.ga.l = Date.now()

    window.ga('create', 'UA-37673467-1', 'auto')
    window.ga('send', 'pageview')
  }

  addListeners () {
    $(document).on('router.navigation.go', (event) => {
      if (event.detail) {
        window.ga('set', 'page', event.detail.to)
        window.ga('send', 'pageview')
      }
    })
  }

  setupTagManager () {
    window.dataLayer = window.dataLayer || []

    window.dataLayer.push({
      'gtm.start': Date.now(),
      'event': 'gtm.js',
      'puuid': riotBar.getPuuid()
    })

    window.dataLayer.push('consent', 'default', {
      ad_storage: 'denied',
      analytics_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      wait_for_update: 500
    })

    window.dataLayer.push('set', 'ads_data_redaction', true)

    this.loadTagManager()
  }

  loadTagManager () {
    window.require([url])
  }

  /**
   * Sends an event to Google Analytics.
   *
   * @param {Object} eventData
   * @param {string} eventData.eventCategory Typically the object that was interacted with (e.g. 'Video')
   * @param {string} eventData.eventAction The type of interaction (e.g. 'play')
   * @param {string} [eventData.eventLabel] Useful for categorizing events (e.g. 'Fall Campaign')
   * @param {number} [eventData.eventValue] A numeric value associated with the event (e.g. 42)
   */
  sendEvent (eventData) {
    window.ga('send', {
      hitType: 'event',
      ...eventData
    })
  }

  getLeagueLabel (leagueSlug, page) {
    return `${page}-${leagueSlug}`
  }

  // https://docs.google.com/document/d/1GkDXzOgiSvpw4Kbn4mnbW-MSWMYzVk_Fu6x_n0Ws-Fs/edit
  getLeagueEventValue (selectedLeague, hasLocalData, isMultiselect) {
    const isForcedSelection
      = selectedLeague.displayPriority?.status === leaguePriority.FORCE_SELECTED
    const isMultiSelectDefault
      = isMultiselect
      && selectedLeague.displayPriority?.status === leaguePriority.SELECTED
    const isSingleSelectDefault = !isMultiselect && !hasLocalData

    return isForcedSelection || isMultiSelectDefault || isSingleSelectDefault
      ? 2 // selection is default selected
      : 1 // selection is user selected
  }

  pushToDataLayer (data) {
    window.dataLayer && window.dataLayer.push(data)
  }

  pushSelectedLeagues (selectedLeagues, hasLocalData, page) {
    const dataLayerMap = {}

    selectedLeagues.forEach((selectedLeague) => {
      const eventValue = this.getLeagueEventValue(
        selectedLeague,
        hasLocalData,
        selectedLeagues.length > 1
      )
      const leagueLabel = this.getLeagueLabel(selectedLeague.slug, page)

      dataLayerMap[leagueLabel] = eventValue
    })

    this.pushToDataLayer(dataLayerMap)
  }
}

export default new Google()
