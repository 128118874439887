import { Component, Logger } from 'lib'
import locale from 'Common/Locale/Locale'
import Image from 'Component/Asset/Image/Image'
import analytics from 'Common/Service/Analytics/Analytics'

const partnerImageSrc = '/partners/ubereats.png'
const partnerImageAlt = 'Order with Uber Eats'
const partnerHref
  = 'https://eats.sng.link/A99vk/npwh?_dl=ubereats%3A%2F%2Featswebfeed%3Ffeed-path%3Dby-id%26id%3Driotgames_2024_us%26feed-transition%3Dpush%26feed-layout%3Dedge-to-edge%26funnel-path%3DGIFT_SHOP%26utm_campaign%3Dbd_us_ca_riot_sponsorship_campaign_w_a&_smtype=3&pscn=bd_apac_r_instagram_so_UBRj48pqx54edprgq668zsj&pcn=bd_us_ca_riot-sponsorship-campaign_w_a'
const allowedCountries = ['CA', 'US']
const allowedTournamentIds = [
  '113476402439116806', // LCP season kickoff DEV
  '112523529392628088', // LCS TEST
  '113470333882171435' // LTA North PROD
]

class PartnerBanner extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
  }

  render () {
    const geoInfo = locale.getGeoInfo()

    if (!allowedTournamentIds.includes(this.props.tournamentId)) {
      this.log.info(
        `Skipped rendering partner banner for tournamentId: ${this.props.tournamentId}`
      )
      return
    }

    if (!allowedCountries.includes(geoInfo?.code)) {
      this.log.info(
        `Skipped rendering partner banner for country code: ${geoInfo.code}`
      )
      return
    }

    this.log.info('Sending analytics event partner_banner:view')
    analytics.trackEvent('partner_banner', {
      type: 'view'
    })

    const onClick = () => {
      this.log.info('Sending analytics event partner_banner:click')
      analytics.trackEvent('partner_banner', {
        type: 'click'
      })
    }

    return (
      <div class="PartnerBanner">
        <a onClick={ onClick } href={ partnerHref } target="_blank">
          <Image
            src={ partnerImageSrc }
            size="x50"
            alt={ partnerImageAlt }
            class="partner-image"
          />
        </a>
      </div>
    )
  }
}

export default PartnerBanner
